/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition-delay: 100ms;
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.chromeframe {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}
#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 200px;
	height: 200px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 5px solid transparent;
	border-top-color: #3498db;

	-webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

	#loader:before {
			content: "";
			position: absolute;
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 50%;
			border: 5px solid transparent;
			border-top-color: #e74c3c;

			-webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
			animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
	}

	#loader:after {
			content: "";
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			bottom: 15px;
			border-radius: 50%;
			border: 5px solid transparent;
			border-top-color: #f9c922;

			-webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
				animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
	}

	@-webkit-keyframes spin {
			0%   { 
					-webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
					transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
			}
			100% {
					-webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
					transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
			}
	}
	@keyframes spin {
			0%   { 
					-webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
					transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
			}
			100% {
					-webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
					transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
			}
	}


.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	/* IE 6/7 fallback */
	*text-indent: -9999px;
}

.ir:before {
	content: "";
	display: block;
	width: 0;
	height: 150%;
}
.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.invisible {
	visibility: hidden;
}

.clearfix:before,
.clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

@media only screen and (min-width: 35em) {
	/* Style adjustments for viewports that meet the condition */
}

@media print,
		 (-webkit-min-device-pixel-ratio: 1.25),
		 (min-resolution: 120dpi) {
}

@media print {
	* {
			background: transparent !important;
			color: #000 !important; 
			box-shadow: none !important;
			text-shadow: none !important;
	}

	a,
	a:visited {
			text-decoration: underline;
	}

	a[href]:after {
			content: " (" attr(href) ")";
	}

	abbr[title]:after {
			content: " (" attr(title) ")";
	}

	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
			content: "";
	}

	pre,
	blockquote {
			border: 1px solid #999;
			page-break-inside: avoid;
	}

	thead {
			display: table-header-group; 
	}

	tr,
	img {
			page-break-inside: avoid;
	}

	img {
			max-width: 100% !important;
	}

	@page {
			margin: 0.5cm;
	}

	p,
	h2,
	h3 {
			orphans: 3;
			widows: 3;
	}

	h2,
	h3 {
			page-break-after: avoid;
	}
}

#loader-wrapper .loader-section {
	position: fixed;
	top: 0;
	width: 51%;
	height: 100%;
	background: #888;
	z-index: 1000;
}

#loader-wrapper .loader-section.section-left {
	left: 0;
}

#loader-wrapper .loader-section.section-right {
	right: 0;
}

#loader {
	z-index: 1001; 
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
	-webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
	transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */
}

.loaded #loader-wrapper .loader-section.section-right {
	-webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
	transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */
}

.loaded #loader {
	opacity: 0;
}

.loaded #loader-wrapper {
	visibility: hidden;
}

.loaded #loader {
	opacity: 0; 
	transition: all 0.3s ease-out;
}

.loaded #loader-wrapper .loader-section.section-right,
.loaded #loader-wrapper .loader-section.section-left { 
	transition: all 0.3s 0.3s ease-out;
}

.loaded #loader-wrapper {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%); 
	transition: all 0.3s 0.6s ease-out;
}
